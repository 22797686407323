<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <div v-if="user_role == 'assistant'">
              <h2 class="content-header-title float-left pr-1 mb-0">
                {{ role }}
              </h2>
            </div>
            <div v-else>
              <h2 class="content-header-title float-left pr-1 mb-0">
                {{ user_role.charAt(0).toUpperCase() + user_role.slice(1) }}
              </h2>
            </div>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Add </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form @submit="addForm" method="post">
        <b-card>
          <h3 class="mb-2 top-header">
            <feather-icon
              icon="ArrowLeftIcon"
              size="24"
              class="mr-2"
              @click="goBack"
            />Basic Information
          </h3>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Name<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="formData.name"
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Email<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    type="email"
                    v-model="formData.email"
                    name="email"
                    placeholder="Email"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Username<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    type="text"
                    v-model="formData.user_name"
                    name="user_name"
                    placeholder="Enter Username"
                    autocomplete="new-username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Status" label-for=" status">
                <template v-slot:label>
                  Status<span class="text-danger">*</span>
                </template>
                <div class="d-flex mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <div class="d-flex">
                      <b-form-radio
                        v-model="formData.status"
                        name="status"
                        value="Active"
                        class="mr-2"
                        checked
                        :aria-describedby="ariaDescribedby"
                      >
                        Active
                      </b-form-radio>
                      <b-form-radio
                        v-model="formData.status"
                        name="status"
                       	value="Inactive"
                      >
                        Inactive
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Password<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  :rules="{ required: true, min: 8 }"
                >
                  <b-form-input
                    type="password"
                    v-model="formData.password"
                    name="password"
                    placeholder="Enter Password"
                    autocomplete="new-password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="Confirm Password">
                <template v-slot:label>
                  Confirm Password<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  :rules="{
                    required: true,
                    min: 8,
                  }"
                >
                  <b-form-input
                    type="password"
                    v-model="formData.cpassword"
                    name="cpassword"
                    placeholder="Confirm Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Phone No<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  :rules="{
                    required: true,
                    integer: true,
                    min: 10,
                  }"
                >
                  <b-form-input
                    type="phone"
                    v-model="formData.phone_no"
                    name="phone_no"
                    placeholder="Enter Phone No"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group class="mb-1">
                <template v-slot:label> Profile Image </template>
                <b-form-file
                  v-model="user_image"
                  plain
                  @change="onFileChange"
                />
              </b-form-group>
              <div v-if="url">
                <div class="profile-image">
                  <img :src="url" height="90" />
                  <div class="remove-image">
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="deleteIcon"
                      @click="resetImage"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- address information -->
          <h3 class="mb-2 mt-2 top-header">Address Information</h3>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <template v-slot:label>
                  Address<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <b-form-textarea
                    type="text"
                    v-model="formData.address"
                    name="address"
                    placeholder=""
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  State<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="State Name"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedState"
                      :options="stateList"
                      label="state_name"
                      :reduce="(e) => e.id"
                      @input="getDistrict"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  District<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="District Name"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedDistrict"
                      :options="districtList"
                      label="district_name"
                      :reduce="(e) => e.id"
                      @input="getCity"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  City<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="City Name"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedCity"
                      :options="cityList"
                      :reduce="(e) => e.id"
                      label="city_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
          </b-row>
          <div>
            <b-button
              type="submit"
              variant="primary"
              value="Submit"
              class="mr-1 mb-5"
            >
              Submit
            </b-button>
            <!-- <input type="submit" value="Submit" /> -->
          </div>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import * as Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BFormSelect,
  BFormDatepicker,
  BFormRadio,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormRadio,
    BFormSelect,
    BFormDatepicker,
    flatPickr,
    vSelect,
    Logo,
    BFormFile,
  },
  props: {
    permissionName: {
      type: String,
    },
  },
  data() {
    return {
      stateList: [],
      districtList: [],
      cityList: [],
      selectedState: null,
      selectedDistrict: null,
      selectedCity: null,
      user_image: null,
      companyId: null,
      url: null,
      formData: {
        name: null,
        email: null,
        user_name: null,
        password: null,
        status:'Active',
        phone_no: null,
        address: null,
      },
      user_role: window.location.pathname.split("/")[2],
      role: "Assistant Manager",
    };
  },
  created: function () {
    this.companyId = this.$route.params.companyId;
    this.getState();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getState() {
      axiosIns
        .get(`web/get-state-list`)
        .then((response) => {
          this.stateList = response.data.state_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getDistrict(id) {
      axiosIns
        .get(`web/get-district-list?state_id=${id}`)
        .then((response) => {
          this.districtList = response.data.districts_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getCity(id) {
      axiosIns
        .get(`web/get-city-list?district_id=${id}`)
        .then((response) => {
          this.cityList = response.data.city_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    addForm(e) {
      e.preventDefault();
      var data = new FormData();
      if (this.user_role === "assistantmanager") {
        this.user_role = "assistant_manager";
      }
      data.append("name", this.formData.name);
      data.append("user_name", this.formData.user_name);
      data.append("password", this.formData.password);
      data.append("email", this.formData.email);
      data.append("status", this.formData.status);
      data.append("phone_no", this.formData.phone_no);
      data.append("city", this.selectedState);
      data.append("district", this.selectedDistrict);
      data.append("state", this.selectedCity);
      data.append("address", this.formData.address);
      data.append("user_image", this.user_image);
      data.append("user_role", this.user_role);
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let companyId = this.companyId;
          let url = "";
          if (companyId) {
            url = `web/company/${companyId}/storeUser`;
            this.user_role = window.location.pathname.split("/")[4];
            data.append("user_role", this.user_role);
          } else {
            url = `web/storeUser`;
          }
          axiosIns
            .post(url, data, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  // title: `Welcome `,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Data Added Successfully `,
                },
              });
              this.user_role = window.location.pathname.split("/")[2];

              if (this.user_role == "assistant") {
                this.user_role = "assistant_manager";
              }
             
              this.$router.push({
                path: `/apps/${this.user_role.toLowerCase()}/list`,
              });
            })
            .catch((error) => {
              const data = error.response.data.errors;
              
              let arr = Object.values(data).flat().toString(",");

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  // title: `Welcome `,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: arr,
                },
              });
            });
        }
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    resetImage() {
      this.url = "";
      this.user_image = null;
    },
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.top-header {
  border-bottom: 1px solid #38c06c;
  padding: 1rem 0rem;
}
.profile-image {
  position: relative;
  border: 1px dashed #d9d9d9;
  height: 100px;
  width: 100px;
  padding: 5px 5px;
  text-align: center;
  border-radius: 5px;
  margin-top: 2rem;
}
.deleteIcon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.remove-image {
  position: absolute;
  top: -7px;
  right: -10px;
  box-shadow: 0 9px 16px 0 rgba(24, 28, 50, 0.25) !important;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: #3f4254;
  background-color: #fff;
  border-color: #fff;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
